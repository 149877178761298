@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins/breakpoints";

footer {
    background-color: #1f2125;
    padding: 40px;
    color: white;
    width: 100%;

    @include media-breakpoint-down(md) {
        padding: 40px 5px;
    }

    .large-screen-align-right {
        text-align: right;

        @include media-breakpoint-down(md) {
            text-align: left;
        }

        .main-button-container {
            @include media-breakpoint-down(md) {
                margin-top: 20px;
                text-align: center;
            }
        }

        .large-screen-align-right-content {
            max-width: 350px;
            margin: 0 auto;
        }
    }

    .footer-top-text {
        font-size: 0.9rem;
        font-weight: 200;
    }

    img {
        max-height: 40px;
        margin-top: 10px;
    }

    .image-brand-name {
        margin-top: 10px;
    }

    .footer-dot-separator {
        margin: 40px 0;
        width: 100%;
        opacity: 0.2;
        border: dashed 0.5px #ffffff;
        height: 2px;
    }

    .small-info {
        display: block;
        font-size: 8px;
        text-align: right;
        opacity: 0.6;
        font-family: Avenir;
    }

    .footer-link {
        display: block;
        color: white;

        &:hover {
            text-decoration: none;
            font-weight: bold;
        }
    }

    .footer-phone-mail {
        display: grid;
        grid-template-rows: repeat(2, 40px);
        grid-template-columns: auto auto;

        @include media-breakpoint-down(md) {
            max-width: 210px;
            margin: auto;
        }

        .footer-phone {
            background-image: url(https://app-mail-asset.bizzw.com/img/util/phonegreen.png);
            background-repeat: no-repeat;
            background-size: 100%;
            color: #6eb13d;
            font-weight: bold;
            padding: 0.05rem 4rem 0.05rem 0.3rem;
            text-align: center;
            text-decoration: none;
            white-space: nowrap;
            font-size: 16px;
            display: inline-block;

            span {
                display: inline-block;
                position: relative;
                top: -4px;
                font-size: 12px;
            }
        }

        a:hover {
            cursor: pointer;
            text-decoration: none;
        }

        .mail-block {
            a {
                color: white;
            }
        }
    }

    .card {
        background-color: #1f2125;
        border-color: transparent;

        .card-header {
            padding: 0;
            border-color: transparent;
            background-color: transparent;

            .footer-card-title {
                text-align: left;
                margin-bottom: 0;
                font-size: 1rem;
                margin-bottom: 10px;

                @include media-breakpoint-down(md) {
                    text-align: center;
                    position: relative;

                    &:after {
                        content: '+';
                        position: absolute;
                        right: 10px;
                    }
                }
            }
        }

        .card-body {
            padding: 0;
            font-size: 0.9rem;
            font-weight: 200;

            @include media-breakpoint-down(md) {
                max-height: 0;
                overflow: hidden;
                margin-bottom: 20px;
                transition: max-height 0.5s ease;
            }
        }

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        &.active {

            @include media-breakpoint-down(md) {
                .footer-card-title:after {
                    content: '-';
                }

                .card-body {
                    max-height: 500px;
                }
            }
        }
    }

    .small-center {
        text-align: left;
        padding-top: 0;

        @include media-breakpoint-down(md) {
            text-align: center;
            padding-top: 40px;
        }
    }

    .social-icon-container {
        text-align: left;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .footer-bottom {
        opacity: 0.6;
        text-align: center;
        font-size: 0.8rem;
    }

    .footer-payment-card {
        height: 20px;
        max-width: 100%;
    }

}
