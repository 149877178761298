@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins/breakpoints";

@import "./info-banner.scss";

.header {
    border-bottom: 1px solid $blue-grey;
    background-color: white;
    .container {
        @include media-breakpoint-down(md) {
            text-align: center;
            margin: 0;
            padding: 0;
            max-width: 100%;
        }
    }

    .bg-light {
        background-color: white !important;
    }

    .navbar {
        .navbar-brand {
            img {
                max-height: 34px;
            }
        }
        .nav-link {
            color: $blue-grey !important;
            @include media-breakpoint-down(md) {
                color: white !important;
            }
            .signin-icon {
                display: inline-block;
                transform: rotate(180deg);
            }
            padding: 0 8px;
            margin: 8px 0;
            &.active {
                background-color: $light-blue;
                @include media-breakpoint-down(md) {
                    background-color: #1e436e;
                    font-weight: bold;
                }
            }
        }
        .header-separator {
            margin: 5px 20px 0;
            width: 1px;
            border: 1px solid #eee;
            height: 35px;
        }
        .navbar-nav {
            width: 100%;
            justify-content: space-around;
        }

        .nav-item {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
            .nav-link {
                @include media-breakpoint-down(md) {
                    display: inline-block;
                }
            }
        }

        .header-svg-nav-item {
            display: inline-block;
            .header-svg {
                height: 22px;
                width: 22px;
                margin: 10px;
                &.white-svg {
                    display: inline-block;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
                &.blue-svg {
                    @include media-breakpoint-down(md) {
                        display: inline-block;
                    }
                    display: none;
                }
            }
        }

        .header-login {
            margin-left: 10px;
            margin-top: 10px;

            a {
                margin-left: 5px;
                color: #1e436e;
                font-weight: 700;
            }
        }
    }

    .image-brand {
        display: flex;
        img {
            max-height: 40px !important;
        }
        .image-brand-name {
            color: $blue-grey;
            font-size: 17px;
            white-space: pre-line;
            display: inline-block;
            line-height: 17px;
            margin-left: 10px;
            position: relative;
            top: 10px;
            &.white-color {
                color: white;
            }
        }
    }
}
