.home {

    .home-title {
        color: #002856;
    }

    .home-box {
        padding: 10px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
        margin-bottom: 30px;
        transition: all .3s;
        height: 190px;
        overflow-x: scroll;

        &:hover {
            box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
        }

        .home-box-star {
            display: inline-block;
            width: 50%;

            svg {
                color: #002856;
            }
        }

        .home-box-message {
            min-height: 96px;
        }

        .home-box-date {
            display: inline-block;
            width: 50%;
            text-align: right;
            font-size: 12px;
        }

        .home-box-from {
            text-align: right;
            font-size: 12px;
        }
    }

}