@import '../util.scss';

.header-info-banner {
    background-color: $light-blue;
    color: $blue-grey;
    text-align: center;

    .header-info-banner-head {
        position: relative;

        .header-info-banner-head-close {
            position: absolute;
            right: 0;
            cursor: pointer;
            font-weight: 900;
        }

        .header-info-banner-head-text {
            display: inline-block;
            cursor: pointer;
            padding-right: 25px;

            @include media-breakpoint-down(md) {
                font-size: 12px;
            }

            &:after {
                display: inline-block;
                margin-left: .255em;
                vertical-align: .255em;
                content: "";
                border-top: .3em solid;
                border-right: .3em solid transparent;
                border-bottom: 0;
                border-left: .3em solid transparent;
            }
        }
    }

    .header-info-banner-content {
        max-height: 0;
        transition: max-height 0.25s ease-out;
        overflow: hidden;
        text-align: center;
        padding: 0;

        .header-info-banner-content-text {
            padding: 10px 0;
        }
    }

    &.active {
        .header-info-banner-head-text::after {
            border-bottom: .3em solid;
            border-top: 0;
        }

        .header-info-banner-content {
            max-height: 1000px;
            transition: max-height 0.25s ease-in;
            padding: 10px 0;
        }
    }
}
