@import "./node_modules/bootstrap/scss/bootstrap";

.main-button {
    font-size: 20px;
    padding: 15px 30px;
    margin: 0;
    background-color: #4498e0;
    color: #ffffff;
    line-height: 55px;
    border: none;

    &:hover {
        text-decoration: none;
        color: #002856;
        -webkit-box-shadow: 0px 0px 24px -10px #e0e0e0;
        box-shadow: 0px 0px 24px -10px #e0e0e0;
    }
}
